import { Box, unityTheme, CircleLoader } from "@ntpkunity/controls";
import { LayoutWithSideNavComponent } from "../../../modules";
import { getToken } from "../../../helpers/Localstorage";
import { IframeWrap, LoaderWrap } from "../../../shared/theme/iframeWrap";
import { useState } from "react";
import { useTheme } from "@mui/material";

export const QuickQuote = () => {
  let accessToken = getToken().access_token;
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  return (
    <LayoutWithSideNavComponent theme={unityTheme}>
      <IframeWrap>
        {loading ? (
          <LoaderWrap theme={theme} className="loader-wrap">
            <Box theme={theme} className="loader">
              <CircleLoader theme={theme} size="lg" />
            </Box>
          </LoaderWrap>
        ) : null}
        <iframe
          width={"100%"}
          height={"100%"}
          style={{ display: loading ? "none" : "block" }}
          onLoad={() => setLoading(false)}
          src={`${
            process.env.QUOTE_URL
          }/route-to-page?page-path=quote&access-token=${accessToken}&tenant_id=${
            getToken().tenant_id
          }`}
          frameBorder="0"
          title="quote-calculation"
        ></iframe>
      </IframeWrap>
    </LayoutWithSideNavComponent>
  );
};
